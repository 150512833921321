
































































































































































































































































import { Component, Prop } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import Distribution1 from '@/views/project/components/distribution-round1.vue'
import ScaDetail from '@/views/sca/ScaDetail_tab.vue'

@Component({ name: 'ScaDiaDetail', components: { Distribution1, ScaDetail } })
export default class ScaDiaDetail extends VueBase {
  private dialogInfo: any = {}
  private detailInfo: any = {}
  private tableLoading = false
  private tableData: any = []
  private activeName: any = []
  private dialogFlag = false
  private licenseObj: any = {}

  async getAssetVuls() {
    this.tableLoading = true
    const res = await this.services.sca.assetVuls({
      package_name: this.$route.query.package_name,
      package_version: this.$route.query.package_version,
      language_id: this.$route.query.language_id,
      page: this.page,
      page_size: this.pageSize,
    })
    this.tableLoading = false
    this.tableData.push(...res.data)
    this.total = res.page.alltotal
  }

  private projects: any = []
  private projects_total = 0
  private projects_pageSize = 10
  private projects_page = 1
  private total = 0
  private pageSize = 10
  private page = 1

  async getProjects() {
    let params: any = {
      package_name: this.$route.query.package_name,
      package_version: this.$route.query.package_version,
      language_id: this.$route.query.language_id,
      page: this.projects_page,
      page_size: this.projects_pageSize,
    }
    if (this.$route.query.projectId) {
      params.project_id = this.$route.query.projectId
    }
    const res = await this.services.sca.assetProjects(params)
    this.projects.push(...res.data)
    this.projects_total = res.page.alltotal
  }
  tableSizeChange(val: number) {
    this.pageSize = val
    this.getAssetVuls()
  }
  tableCurrentChange(val: number) {
    this.page = val
    this.getAssetVuls()
  }
  handleSizeChange(val: number) {
    this.projects_pageSize = val
    this.getProjects()
  }
  handleCurrentChange(val: number) {
    this.projects_page = val
    this.getProjects()
  }
  async handleChange(val: any) {
    if (val) {
      console.log('val', val)
      const res = await this.services.sca.assetProjectId({
        package_name: this.$route.query.package_name,
        package_version: this.$route.query.package_version,
        language_id: this.$route.query.language_id,
        project_id: val,
      })
      this.projects = this.projects.map((item: any) => {
        if (item.project === val) {
          this.$set(item, 'content', res.data)
        }
        return item
      })
    }
  }
  private toVulnD(item: any) {
    this.dialogFlag = true
    this.dialogInfo = item
    // this.$router.push(
    //   `/vuln/scaDetail/${item.asset_vul_id}/1?status=1&id=${item.asset_vul_id}`
    // )
  }

  toProject(id: any, index: any) {
    if (index === 0 && this.$route.query.projectId) {
      return
    }
    this.$router.push('/project/appDetail/' + id)
  }
  goBack = () => {
    this.$router.go(-1)
  }

  created() {
    this.getDetail()
    this.getAssetVuls()
    this.getProjects()
  }
  private level_data: any = []
  async getDetail() {
    const res = await this.services.sca.getScaDetail({
      package_name: this.$route.query.package_name,
      package_version: this.$route.query.package_version,
      language_id: this.$route.query.language_id,
    })
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.detailInfo = res.data
    this.licenseObj = res.data.license_list[0]
    this.level_data = [
      {
        level_id: 1,
        level_name: '高危',
        num: res.data.vul_high_count || 0,
      },
      {
        level_id: 2,
        level_name: '中危',
        num: res.data.vul_medium_count,
      },
      { level_id: 3, level_name: '低危', num: res.data.vul_low_count },
      {
        level_id: 4,
        level_name: '无风险',
        num: res.data.vul_info_count,
      },
    ]
  }
  private getTagColoe(language: string) {
    switch (language) {
      case 'Java':
        return 'danger'
      case 'PYTHON':
        return ''
      default:
        return ''
    }
  }
  private levelObj = {
    1: '高危',
    2: '中危',
    3: '低危',
    4: '无风险',
  }

  private levelColor(level: any) {
    switch (level) {
      case 1:
        return { label: '高', color: '#E56363', bg: 'rgba(229, 99, 99, 0.1)' }
      case 2:
        return { label: '中', color: '#F49E0B', bg: 'rgba(244, 158, 11, 0.1)' }
      case 3:
        return { label: '低', color: '#2F90EA', bg: 'rgba(47, 144, 234, 0.1)' }
      case 4:
        return { label: '无', color: '#ABB2C0', bg: 'rgba(172, 180, 196, 0.1)' }
    }
  }

  private switchLevel(level: any) {
    switch (level) {
      case 1:
        return { label: '高', color: '#E56363', bg: 'rgba(229, 99, 99, 0.1)' }
      case 2:
        return { label: '中', color: '#F49E0B', bg: 'rgba(244, 158, 11, 0.1)' }
      case 3:
        return { label: '低', color: '#2F90EA', bg: 'rgba(47, 144, 234, 0.1)' }
      case 0:
        return {
          label: '无风险',
          color: '#ABB2C0',
          bg: 'rgba(172, 180, 196, 0.1)',
        }
    }
  }
}
